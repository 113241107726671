<template>
  <IngeplandeCursus />
</template>

<script>
import IngeplandeCursus from "@/components/cursussen/IngeplandeCursus";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    IngeplandeCursus,
  },
  metaInfo: {
    title: "Cursusinplannen | Dashboard",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>
