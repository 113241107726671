<template>
  <v-container>
    <form ref="form" @submit.prevent="submitForm">
      <v-stepper v-model="e13" vertical>
        <v-stepper-step step="1">Cursus informatie</v-stepper-step>
        <v-stepper-content step="1">
          <v-card>
            <v-card-title></v-card-title>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  dense
                  outlined
                  label="Artikelnummer"
                  v-model="form.artikelnummer"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  outlined
                  label="Titel"
                  v-model="form.titel"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  outlined
                  type="number"
                  label="Aantal Personen"
                  v-model="form.aantalPersonen"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  dense
                  outlined
                  label="Aantal uren klassikaal"
                  type="number"
                  v-model="form.aantalUren"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  outlined
                  label="Aantal uren online"
                  type="number"
                  v-model="form.aantalUrenOnline"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  outlined
                  prefix="€"
                  type="number"
                  label="Prijs"
                  v-model="form.prijs"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-autocomplete
                  small-chips
                  deletable-chips
                  dense
                  outlined
                  :items="categorieen"
                  label="Categorieën selecteren"
                  v-model="form.categories"
                  item-text="naam"
                  item-value="id"
                  multiple
                ></v-autocomplete>
              </v-col>

              <v-col cols="4">
                <v-autocomplete
                  dense
                  outlined
                  :items="vormen.flat()"
                  label="Cursusvorm selecteren"
                  v-model="form.vorm"
                  item-text="naam"
                  item-value="naam"
                ></v-autocomplete>
              </v-col>

              <v-col cols="4">
                <v-autocomplete
                  dense
                  outlined
                  :items="certificaten.flat()"
                  label="Certificaat selecteren"
                  v-model="form.certificaat"
                  item-text="naam"
                  item-value="naam"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  dense
                  outlined
                  :items="tags"
                  label="URL tag selecteren"
                  v-model="form.tag"
                  item-text="naam"
                  item-value="naam"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" style="margin-left:14px">
                <v-switch
                  v-model="form.populair"
                  inset
                  :label="`Populair: ${form.populair ? 'Ja' : 'Nee'}`"
                ></v-switch>
              </v-col>
            </v-row>
            <div style="margin-top: 5px">
              <v-btn color="primary" @click="e13 = 2">Volgende</v-btn>
              <v-btn color="error" @click="goBack()" text>Annuleer</v-btn>
            </div>
          </v-card>
        </v-stepper-content>

        <v-stepper-step step="2"
          >Omschrijving en Cursusinformatie</v-stepper-step
        >

        <v-stepper-content step="2">
          <v-card>
            <v-row>
              <v-col cols="12">
                <label>Omschrijving en informatie *</label>
                <vue-editor
                  ref="editor1"
                  v-model="form.omschrijving"
                  class="custom-editor"
                  :editorToolbar="customToolbar"
                ></vue-editor>
              </v-col>
            </v-row>
            <div style="margin-top: 5px">
              <v-btn style="margin:2px" color="error" @click="goBack()"
                >Annuleer</v-btn
              >
              <v-btn color="warning" @click="e13 = 1" text>Vorige</v-btn>
              <v-btn color="primary" @click="addTraining()"
                >Opslaan en volgende >>></v-btn
              >
            </div>
          </v-card>
        </v-stepper-content>

        <v-stepper-step step="3">Afbeeldingen</v-stepper-step>

        <v-stepper-content step="3">
          <v-card>
            <v-row>
              <v-col>
                <v-form ref="form" @submit.prevent="submitForm">
                  <v-file-input
                    v-model="files"
                    label="Selecteer een afbeelding"
                    multiple
                    :show-size="true"
                    @change="handleFileChange"
                  ></v-file-input>
                </v-form>
                <v-btn small color="primary" @click="uploadFiles()">
                  Afbeeldingen uploaden
                </v-btn>
              </v-col>
            </v-row>
            <div style="margin-top: 5px">
              <v-btn style="margin:2px" color="error" @click="goBack()"
                >Annuleer</v-btn
              >
              <v-btn color="warning" @click="e13 = 2" text>Vorige</v-btn>
            </div>
          </v-card>
        </v-stepper-content>
      </v-stepper>
    </form>

    <v-dialog v-model="showInstructionsDialog" max-width="500px">
      <v-card>
        <v-card-title>Instructies</v-card-title>
        <v-card-text>
          <template v-if="e13 === 1">
            <p>
              Hier kunnen de cursussen en de afbeeldingen van de cursussen
              toegevoegd worden.
            </p>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="showInstructionsDialog = false"
            >Sluiten</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { API } from "../api/api";
import apiCategorie from "../api/apiCategorie";
import apiTraining from "../api/apiTraining";
import apiCertificaat from "../api/apiCertificaat";

import { Quill } from "vue-quill-editor";
import { VueEditor } from "vue2-editor";
function getFontName(font) {
  return font.toLowerCase().replace(/\s/g, "-");
}

const fontList = [
  "Arial",
  "Courier",
  "Garamond",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];
const fontNames = fontList.map((font) => getFontName(font));
const fonts = Quill.import("formats/font");
fonts.whitelist = fontNames;
Quill.register(fonts, true);

// Add fonts to CSS style
let fontStyles = "";
fontList.forEach(function(font) {
  let fontName = getFontName(font);
  fontStyles +=
    ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" +
    fontName +
    "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" +
    fontName +
    "]::before {" +
    "content: '" +
    font +
    "';" +
    "font-family: '" +
    font +
    "', sans-serif;" +
    "}" +
    ".ql-font-" +
    fontName +
    "{" +
    " font-family: '" +
    font +
    "', sans-serif;" +
    "}";
});

const node = document.createElement("style");
node.innerHTML = fontStyles;
document.body.appendChild(node);

var Size = Quill.import("attributors/style/size");

Size.whitelist = [
  "9px",
  "10px",
  "11px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
];
Quill.register(Size, true);

export default {
  components: {
    VueEditor,
  },
  data: () => ({
    showInstructionsDialog: false,
    e13: 1,
    tags: ["ehbo", "bhv", "overig"],
    categorieen: [],
    certificaten: [],
    files: [],
    vormen: ["Klassikaal", "Online + klassikaal", "Online"],
    form: {
      populair: false,
      certificaat: "",
      vorm: "",
      titel: "",
      aantalPersonen: "",
      aantalUren: "",
      prijs: null,
      omschrijving: "",
      cursusinformatie: "",
    },
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      ["bold", "italic", "underline"],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ font: fonts.whitelist }],
      [
        {
          size: [
            "9px",
            "10px",
            "11px",
            "12px",
            "14px",
            "16px",
            "18px",
            "20px",
            "22px",
            "24px",
            "26px",
            "28px",
          ],
        },
      ],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ["link", "image"],
      ["clean"],
    ],
  }),

  mounted() {
    this.fetchCategorie();
    this.fetchCertificaat();
    const formData = localStorage.getItem("formData");
    if (formData) {
      this.form = JSON.parse(formData);
    }
  },
  methods: {
    fetchCategorie() {
      apiCategorie.haalCategorieOp().then((data) => {
        this.categorieen = data;
      });
    },
    fetchCertificaat() {
      apiCertificaat.haalCertificaat().then((data) => {
        this.certificaten = data;
      });
    },
    goBack() {
      this.$router.push("/Cursussenbeheren");
    },
    nextStep() {
      this.currentStep = "complete";
    },
    resetForm() {
      this.form = {
        titel: "",
        aantalPersonen: "",
        aantalUren: "",
        prijs: null,
        omschrijving: "",
        cursusinformatie: "",
        cursusDag: "",
        categories: [],
      };
      this.e13 = 1;
    },

    async addTraining() {
      try {
        await apiTraining.addTraining(this.form).then((data) => {
          this.form.artikelNummer = data.artikelnummer;
        });
        this.$swal.fire(
          "Succes!",
          "Het artikel is aangemaakt. Voeg nu afbeeldingen toe.",
          "success"
        );
        this.e13 = 3;
      } catch (error) {
        console.error("API call failed:", error);
        this.$swal.fire("Foutmelding!", "Probeer het nogmaals!", "error");
      }
    },

    //Upload
    handleFileChange(files) {
      this.files = files;
    },

    async uploadFiles() {
      if (this.files.length > 0) {
        for (let i = 0; i < this.files.length; i++) {
          const formData = new FormData();
          formData.append("file", this.files[i]);
          try {
            const response = await API.post(
              `/api/rest/v2/folder/processing/upload/${this.form.artikelNummer}`,
              formData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );
            console.log("Bestand succesvol geüpload", response);
          } catch (error) {
            console.error("Er is een fout opgetreden bij het uploaden", error);
            this.$swal.fire("Upload fout", "", "error");
            return;
          }
        }
        this.files = [];
        this.$swal.fire(
          "Alle bestanden zijn succesvol geüpload",
          "",
          "success"
        );
      }
    },
  },
};
</script>

<style scoped>
.custom-text-field {
  font-family: "Arial", sans-serif;
  font-size: 16px;
}

.custom-editor .ql-container {
  font-family: "Arial", sans-serif;
  font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9px"]::before {
  content: "9";
  font-size: 9px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10";
  font-size: 10px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="11px"]::before {
  content: "11";
  font-size: 11px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12";
  font-size: 12px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14";
  font-size: 14px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16";
  font-size: 16px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18";
  font-size: 18px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20";
  font-size: 20px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22";
  font-size: 22px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24";
  font-size: 24px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="26px"]::before {
  content: "26";
  font-size: 26px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="28px"]::before {
  content: "28";
  font-size: 28px !important;
}
</style>
