]<template>
  <v-row>
    <v-col class="d-flex justify-end">
      <v-dialog transition="dialog-top-transition" max-width="1000">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#D84315"
            v-bind="attrs"
            v-on="on"
            style="color:white"
            rounded
          >
            Cursus(sen) inplannen
          </v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary" dark>Cursussen inplannen</v-toolbar>
            <v-card-text>
              <div class="text-h2 pa-12">
                <v-row>
                  <v-col>
                    <v-menu
                      ref="datummenu"
                      v-model="menu3"
                      :close-on-content-click="false"
                      :return-value.sync="form.datum"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.datum"
                          label="Datum selecteren"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.datum"
                        no-title
                        scrollable
                        multiple
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu3 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.datummenu.save(form.datum)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>

                    <!-- Tijd-->
                    <v-menu
                      ref="tijdmenu"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="form.tijd"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.tijd"
                          label="Tijd selecteren"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu2"
                        v-model="form.tijd"
                        format="24hr"
                        full-width
                        @click:minute="$refs.tijdmenu.save(form.tijd)"
                      ></v-time-picker>
                    </v-menu>
                    <v-autocomplete
                      label="Selecteer artikelnummers"
                      chips
                      clearable
                      dense
                      :items="cursusen"
                      filled
                      multiple
                      rounded
                      small-chips
                      v-model="form.artikelnummers"
                      item-text="artikelnummer"
                      item-value="id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="primary" @click="planIn()" rounded small>
                Inplannen
              </v-btn>
              <v-btn text @click="dialog.value = false">Afsluiten</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import apiTraining from "../api/apiTraining";

export default {
  data: () => ({
    form: {
      datum: [],
      tijd: null,
      artikelnummers: [],
    },
    menu3: false,
    menu2: false,
    cursusen: [],
  }),
  async mounted() {
    await this.fetchArtikelNames();
  },
  methods: {
    resetForm() {
      this.form.datum = [];
      this.form.tijd = null;
      this.form.artikelnummers = [];
      this.menu3 = false;
      this.menu2 = false;
    },
    async fetchArtikelNames() {
      try {
        const data = await apiTraining.getNamesTraining();
        this.cursusen = data;
      } catch (error) {
        console.error("Error fetching article names:", error);
      }
    },
    async planIn() {
      console.log("Planning information:", this.form);
      try {
        const response = await apiTraining.plantraining(this.form);
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.$emit("cursusAdded");
        this.resetForm();
      } catch (error) {
        console.error("Error planning training:", error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
/* Add any scoped styles here if needed */
</style>
