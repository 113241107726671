var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"color":"white"},attrs:{"color":"#D84315","rounded":""}},'v-btn',attrs,false),on),[_vm._v(" Cursus(sen) inplannen ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Cursussen inplannen")]),_c('v-card-text',[_c('div',{staticClass:"text-h2 pa-12"},[_c('v-row',[_c('v-col',[_c('v-menu',{ref:"datummenu",attrs:{"close-on-content-click":false,"return-value":_vm.form.datum,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.form, "datum", $event)},"update:return-value":function($event){return _vm.$set(_vm.form, "datum", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Datum selecteren","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.form.datum),callback:function ($$v) {_vm.$set(_vm.form, "datum", $$v)},expression:"form.datum"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","multiple":""},model:{value:(_vm.form.datum),callback:function ($$v) {_vm.$set(_vm.form, "datum", $$v)},expression:"form.datum"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu3 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.datummenu.save(_vm.form.datum)}}},[_vm._v(" OK ")])],1)],1),_c('v-menu',{ref:"tijdmenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.form.tijd,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.form, "tijd", $event)},"update:return-value":function($event){return _vm.$set(_vm.form, "tijd", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Tijd selecteren","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.form.tijd),callback:function ($$v) {_vm.$set(_vm.form, "tijd", $$v)},expression:"form.tijd"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.tijdmenu.save(_vm.form.tijd)}},model:{value:(_vm.form.tijd),callback:function ($$v) {_vm.$set(_vm.form, "tijd", $$v)},expression:"form.tijd"}}):_vm._e()],1),_c('v-autocomplete',{attrs:{"label":"Selecteer artikelnummers","chips":"","clearable":"","dense":"","items":_vm.cursusen,"filled":"","multiple":"","rounded":"","small-chips":"","item-text":"artikelnummer","item-value":"id"},model:{value:(_vm.form.artikelnummers),callback:function ($$v) {_vm.$set(_vm.form, "artikelnummers", $$v)},expression:"form.artikelnummers"}})],1)],1)],1)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary","rounded":"","small":""},on:{"click":function($event){return _vm.planIn()}}},[_vm._v(" Inplannen ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Afsluiten")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }