<template>
  <v-container>
    <div>
      <v-btn color="primary" @click="show = !show"> Switch view</v-btn>
<IngeplandCursusagendaview v-if="!show" />
<IngeplandeCursustabelview v-if="show" />
    </div>
  </v-container>
</template>
<script>
import IngeplandCursusagendaview from './IngeplandCursusagendaview.vue'
import IngeplandeCursustabelview from './IngeplandeCursustabelview.vue'
export default {
  components:{
    IngeplandCursusagendaview,
    IngeplandeCursustabelview
  },
  data: () => ({
    show: false,
  }),
}
</script>