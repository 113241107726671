import { API } from "./api.js";
export default {
  async createCertificaat(data) {
    try {
      const response = await API.post("/api/rest/v2/certificate", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async haalCertificaat() {
    try {
      const response = await API.get("/api/rest/v2/certificate");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async haalEenCertificaatId(id) {
    try {
      const response = await API.get(`/api/rest/v2/certificate/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateCertificaat(id, form) {
    try {
      const response = await API.put(`/api/rest/v2/certificate/${id}`, form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async deleteCertificaat(id) {
    try {
      const response = await API.delete(`/api/rest/v2/certificate/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
