<template>
  <v-container>
    <div>
      <span style="padding-bottom:10px">
        <Cursusinplannen @cursusAdded="fetchIngeplandeCursus" />
      </span>
      <v-text-field
        v-model="search"
        label="Zoek datum"
        class="mb-3"
        placeholder="dd/mm/jjjj"
      ></v-text-field>
      <v-data-table
        :headers="headers"
        :items="filteredCursussen"
        :search="search"
        single-expand
        show-expand
        :group-by="['datum']"
        :group-desc="[false]"
        disable-pagination
        :fixed-header="true"
        height="75vh"
        :hide-default-footer="true"
        class="elevation-1"
        cellClass="printable"
      >
        <template v-slot:[`item.datum`]="{ item }">
          {{ formatDate(item.datum) }}
        </template>
        <template v-slot:top></template>
        <template v-slot:item.details="{ item }">
          <v-card>
            <v-card-title>
              Cursussen op dezelfde datum: {{ formatDate(item.datum) }}
            </v-card-title>
            <v-card-text>
              <v-list dense>
                <v-list-item
                  v-for="cursus in filterCursussenByDate(item.datum)"
                  :key="cursus.id"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{
                      cursus.template.titel
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >Artikelnummer:
                      {{ cursus.template.artikelnummer }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import Cursusinplannen from "./Cursusinplannen.vue";

import apiTraining from "../api/apiTraining";
export default {
  components: {
    Cursusinplannen,
  },
  data: () => ({
    search: "",
    cursussen: [],
    filteredCursussen: [],
    headers: [
      {
        text: "Datum",
        value: "datum",
      },
      {
        text: "Status",
        value: "status",
        sortable: true,
      },
      {
        text: "Geplande Cursussen",
        value: "details",
        sortable: true,
      },
    ],
  }),
  mounted() {
    this.fetchIngeplandeCursus();
  },

  methods: {
    groupByDate(cursussen) {
      return cursussen.reduce((groups, cursus) => {
        const date = this.formatDate(cursus.datum);
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(cursus);
        return groups;
      }, {});
    },
    formatDate(isoString) {
      const date = new Date(isoString);
      const day = date
        .getDate()
        .toString()
        .padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const hours = date
        .getHours()
        .toString()
        .padStart(2, "0");
      const minutes = date
        .getMinutes()
        .toString()
        .padStart(2, "0");
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    },
    filterCursussenByDate(datum) {
      return this.cursussen.filter((cursus) => cursus.datum === datum);
    },
    fetchIngeplandeCursus() {
      apiTraining.getingeplandetraining().then((data) => {
        this.cursussen = data;
        this.filteredCursussen = data; // Initieer met alle cursussen
      });
    },
  },
  watch: {
    search(val) {
      this.filteredCursussen = this.filterCursussen(val);
    },
  },
};
</script>
