import { API } from "./api.js";
export default {
  async createCategorie(data) {
    try {
      const response = await API.post("/api/rest/v2/categorie", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async haalCategorieOp() {
    try {
      const response = await API.get("/api/rest/v2/categorie");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getHoofdCategorieen() {
    try {
      const response = await API.get("/api/rest/v2/categorie/hoofdcategorieen");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async haalEenCategorieId(id) {
    try {
      const response = await API.get(`/api/rest/v2/categorie/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateCategorie(id, form) {
    try {
      const response = await API.put(`/api/rest/v2/categorie/${id}`, form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async deleteCategorie(id) {
    try {
      const response = await API.delete(`/api/rest/v2/categorie/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
