import { API } from "./api.js";
export default {
  async addTraining(data) {
    try {
      const response = await API.post("/api/rest/v2/training", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getTrainingen(pageNumber, q = "") {
    try {
      const response = await API.get(`/api/rest/v2/training`, {
        params: { q, page: pageNumber },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async deleteTraining(id) {
    try {
      const response = await API.delete(`/api/rest/v2/training/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getSingleTraining(id) {
    try {
      const response = await API.get(`/api/rest/v2/training/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updateTraining(id, data) {
    try {
      const response = await API.put(`/api/rest/v2/training/${id}`, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getNamesTraining() {
    try {
      const response = await API.get(`/api/rest/v2/training/names`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async plantraining(data) {
    try {
      const response = await API.post(`/api/rest/v2/training/inplannen`, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getingeplandetraining() {
    try {
      const response = await API.get(`/api/rest/v2/training/inplannen`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
